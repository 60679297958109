import React from 'react'
import type { AuthorResult } from '../data/fragments/AuthorFragment'
import type { LinkResult } from '../data/fragments/LinkFragment'
import { AuthorsList } from './AuthorsList'
import { Button } from './Button'
import style from './HomePageAuthors.module.sass'
import { MarqueeText } from './MarqueeText'

export type HomePageAuthorsProps = {
	headlineTextColor?: string
	headline?: string
	headlineBackgroundColor?: string
	authors: AuthorResult[]
	button?: LinkResult
}

export const HomePageAuthors: React.FunctionComponent<HomePageAuthorsProps> = ({
	headlineTextColor,
	headline,
	headlineBackgroundColor,
	authors,
	button,
}) => {
	const buttonLabel = 'Dej mi víc'

	return (
		<div className={style.wrapper}>
			{headline && (
				<MarqueeText speed={90} bgColor={headlineBackgroundColor} textColor={headlineTextColor}>
					{headline}
				</MarqueeText>
			)}
			<AuthorsList large items={authors} moreVisible />
			{button && (
				<div className={style.button}>
					<Button type="link" link={button} view="dark">
						{buttonLabel}
					</Button>
				</div>
			)}
		</div>
	)
}
