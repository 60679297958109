import React, { useMemo } from 'react'
import { ArticleHeaderView } from '../../generated/content'
import type { RouteName } from '../../generated/routing/components'
import type { RouteProps } from '../../libs/routing/routing'
import { filterNonEmpty } from '../../libs/utils/filterNonEmpty'
import { AstroStories } from '../components/AstroStories'
import { RandomBanner } from '../components/Banner'
import { Container } from '../components/Container'
import { Hero } from '../components/Hero'
import { HomePageAuthors } from '../components/HomePageAuthors'
import { PreFooter } from '../components/PreFooter'
import { ProductOfTheMonth } from '../components/ProductOfTheMonth'
import { SeoHead } from '../components/SeoHead'
import { Spacer } from '../components/Spacer'
import { TagRowGrid } from '../components/TagRowGrid'
import { Trends } from '../components/Trends'
import { WearFomo } from '../components/WearFomo'
import { useTileGridItems } from '../hooks/useTileGridItems'
import { renderLayout } from './@layout'
import style from './home.module.sass'
import { useGtmPageData } from '../utils/useGtmPageData'

export default function HomePage(props: RouteProps<RouteName.home>) {
	const { page } = props
	// const { articles, feed } = page
	const { articles } = page

	const trendsTiles = useTileGridItems(articles.trends, { gtmTileList: 'Trendy' }, false, true)
	// const storiesTiles = useTileGridItems(articles.stories, { gtmTileList: 'Vaše příběhy' })
	const astroStoriesTiles = useTileGridItems(articles.astro, { gtmTileList: 'Astro' }, true, true)
	// const otherTiles = useTileGridItems(articles.other, { gtmTileList: 'Další články' })

	// const binaryPollData = React.useMemo<BinaryPollProps | undefined>(() => {
	// 	if (!page.survey1) {
	// 		return undefined
	// 	}
	// 	return {
	// 		id: page.survey1.id,
	// 		question: page.survey1.primaryText,
	// 		image: page.survey1.image,
	// 		// icon?
	// 		textColor: page.survey1.textColor,
	// 		sideA: {
	// 			answerId: page.survey1.answers[0].id,
	// 			textualAnswer: page.survey1.answers[0].text,
	// 			imageAnswer: page.survey1.answers[0].image,
	// 			backgroundColor: page.survey1.answers[0].backgroundColor,
	// 			voteCount: page.survey1.answers[0].stats?.total ?? 0,
	// 		},
	// 		sideB: {
	// 			answerId: page.survey1.answers[1].id,
	// 			textualAnswer: page.survey1.answers[1].text,
	// 			imageAnswer: page.survey1.answers[1].image,
	// 			backgroundColor: page.survey1.answers[1].backgroundColor,
	// 			voteCount: page.survey1.answers[1].stats?.total ?? 0,
	// 		},
	// 		alreadyVoted: false,
	// 	}
	// }, [page.survey1])

	// const messagesTileData = React.useMemo(() => {
	// 	return {
	// 		header: {
	// 			title: <MaybeRichText>{page.messagesHeadline}</MaybeRichText>,
	// 			leftIcon: page.messagesIconLeft,
	// 			rightIcon: page.messagesIconRight,
	// 		},
	// 		messages: page.messages.map((message) => ({
	// 			id: message.id,
	// 			photo: message.author?.avatar,
	// 			message: <RichText blocks={message.message?.parts ?? []} />,
	// 			isReversed: message.type === HomePageMessageType.left,
	// 			isSender: message.type !== HomePageMessageType.left,
	// 		})),
	// 	}
	// }, [page.messages, page.messagesHeadline, page.messagesIconLeft, page.messagesIconRight])

	const TagsData = React.useMemo(() => {
		return {
			tags: {
				small: true,
				rows: page.tagsLines.map((line) => ({
					items: line.tags.map((tag) => tag.tag).filter(filterNonEmpty),
				})),
			},
		}
	}, [page.tagsLines])

	const preFooterData = React.useMemo(() => {
		return {
			callToAction: {
				headline: page.submitYourWorkHeader,
				text: page.submitYourWorkText,
				buttonLabel: page.submitYourWorkButtonLabel,
			},
		}
	}, [page.submitYourWorkHeader, page.submitYourWorkText, page.submitYourWorkButtonLabel])

	// const router = useRouter()

	// const goToAllArticles = React.useCallback(() => {
	// 	window.addEventListener(
	// 		'routing_finish',
	// 		() => {
	// 			setTimeout(() => {
	// 				scrollBy(
	// 					window,
	// 					(document.getElementById('skip')?.getBoundingClientRect()?.top ?? 0) - 20,
	// 					0
	// 				)
	// 			}, 1000)
	// 		},
	// 		{ once: true }
	// 	)
	// 	router.push('/clanky')
	// }, [router])

	useGtmPageData({ page: { type: 'homepage' } })

	const authors = useMemo(() => {
		const list = props.page.authors.flatMap((author) => author.authors)
		const authorList = list.flatMap((author) => author.author).filter(filterNonEmpty)

		return authorList
	}, [props.page.authors])

	const products = useMemo(() => {
		const list = props.page.wearFomoProducList?.items.flatMap((product) => product.product)
		const productList = list?.flatMap((product) => product).filter(filterNonEmpty)

		return productList
	}, [props.page.wearFomoProducList?.items])

	return (
		<>
			<SeoHead seo={page.seo} titleSuffix="" url="/" />
			{articles.opener && (
				<Hero linking article={articles.opener} headerView={ArticleHeaderView.split} />
			)}
			<section className={style.wrapperTrends}>
				<Trends
					headlineTextColor={page.trendsHeadlineColor}
					headline={page.trendsHeadline}
					headlineBackgroundColor={page.trendsHeadlineBackground}
					tiles={trendsTiles}
					// messageTile={messagesTileData}
					// binaryPoll={binaryPollData}
					button={{ label: 'Dej mi víc', url: page.trendsMoreButton }}
					banners={
						props.page.banners.length > 0 ? (
							<RandomBanner banners={props.page.banners} gtmPosition="homeTop" />
						) : undefined
					}
				/>
			</section>
			<Spacer initial={30} />
			{props.page.tagsLines && (
				<Container>
					<TagRowGrid {...TagsData.tags} />
				</Container>
			)}

			<section className={style.wrapperYourStories}>
				<AstroStories
					headlineTextColor={page.astroHeadlineColor}
					headline={page.astroHeadline}
					headlineBackgroundColor={page.astroHeadlineBackground}
					tiles={astroStoriesTiles}
				/>
			</section>

			{page.productOfTheMonthIsVisible && (
				<Container>
					<section className={style.wrapperYourStories}>
						<ProductOfTheMonth
							title={page.productBanner?.title}
							subtitle={page.productBanner?.subtitle}
							image={page.productBanner?.image}
							linkLabel={page.productBanner?.linkLabel}
							link={page.productBanner?.link}
						/>
					</section>
				</Container>
			)}

			<Container size="full" disableGutters>
				<HomePageAuthors
					headlineTextColor={page.authorsHeadlineColor}
					headline={page.authorsHeadline}
					headlineBackgroundColor={page.authorsHeadlineBackground}
					authors={authors}
					button={page.authorsMoreBtn}
				/>
			</Container>

			<Container disableGutters size="full">
				{products && (
					<WearFomo
						headlineTextColor={page.wearFomoHeadlineColor}
						headline={page.wearFomoHeadline}
						headlineBackgroundColor={page.wearFomoHeadlineBackground}
						linkLabel={page.wearFomoLinkLabel}
						link={page.wearFomoLink}
						products={products}
					/>
				)}
			</Container>

			{/* <FomoFeed
				background={page.fomoFeedBackground}
				feed={feed}
				title={<MaybeRichText>{page.fomoFeedHeadline}</MaybeRichText>}
				partners={page.fomoFeedPartners.map((item) => item.partner).filter(filterNonEmpty)}
			/> */}
			{/* <Container>
				<section className={style.wrapperYourStories}>
					<YourStories headline={page.yourStoriesHeadline} tiles={storiesTiles} />
				</section>
				<section id="dalsi-clanky" className={style.wrapperAllArticles}>
					<OtherArticles
						headline={page.allArticlesHeadline}
						tiles={otherTiles}
						icon={page.allArticlesIcon}
					/>
					<Spacer initial={40} />
					<div style={{ textAlign: 'center' }}>
						<Button
							type="button"
							view="dark"
							onClick={goToAllArticles}
							dataAttributes={{ 'track-click': '{"event":"page_moreArticles"' }}>
							Dej mi ještě víc
						</Button>
					</div>
				</section>
				<section className={style.wrapperTips}>
					<TipsOfTheWeek
						podcast={articles.tips.podcast}
						video={articles.tips.video}
						tip={articles.tips.default}
						headline={page.tipsHeadline}
						stickers={page.tipsStickers}
					/>
				</section>
			</Container> */}
			{/* <Container disableGuttersMobile>
				<section className={style.wrapperSocials}>
					<TileGrid
						className={style.socials}
						perLine={4}
						slidingView
						items={page.embeds.map((embed) => (
							<div key={embed.id} style={{ textAlign: 'center' }}>
								{embed.image && <ContemberImage image={embed.image} />}
								<Spacer initial={10} />
								{embed.link && (
									<Button type="link" view="light" link={embed.link} shadow>
										{embed.buttonLabel}
									</Button>
								)}
							</div>
						))}
					/>
				</section>
			</Container> */}
			<PreFooter {...preFooterData} />
		</>
	)
}

export { renderLayout }
