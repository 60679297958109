import Marquee from 'react-fast-marquee'
import style from './MarqueeText.module.sass'

export function MarqueeText({
	speed,
	children,
	bgColor,
	textColor,
}: {
	speed: number
	children: React.ReactNode
	bgColor: string | undefined
	textColor: string | undefined
}) {
	return (
		<div
			className={style.wrapper}
			style={
				{
					'--loop-bgColor': bgColor,
				} as React.CSSProperties
			}>
			<Marquee autoFill speed={speed}>
				<div
					className={style.looper}
					style={
						{
							'--loop-textColor': textColor,
						} as React.CSSProperties
					}>
					<span className={style.children}>{children}</span>
				</div>
			</Marquee>
		</div>
	)
}
