export function mailto(props: { to: string; cc?: string[]; subject?: string; body?: string }) {
	const { to, ...query } = props
	const queryString = Object.entries(query)
		.map(([key, val]) => {
			if (!val) {
				return ''
			}
			return `${encodeURIComponent(key)}=${encodeURIComponent(String(val))}`
		})
		.join('&')
	return `mailto:${to}${queryString ? `?${queryString}` : ''}`
}
