/* eslint-disable jsx-a11y/alt-text */
import React, { ReactNode, useMemo } from 'react'
import type { LinkResult } from '../data/fragments/LinkFragment'
// import { BinaryPollProps } from './BinaryPoll'
// import { MessagesTileProps } from './MessagesTile'
import { MarqueeText } from './MarqueeText'
import { Spacer } from './Spacer'
import style from './Trends.module.sass'
import { Container } from './Container'
import { Button } from './Button'

export type TrendsProps = {
	headlineTextColor?: string
	headline?: string
	headlineBackgroundColor?: string
	tiles: Array<ReactNode>
	// messageTile?: MessagesTileProps
	// binaryPoll?: BinaryPollProps
	button?: ButtonProps
	banners?: ReactNode
}

type ButtonProps = {
	label: string
	url?: LinkResult
}

export const Trends: React.FunctionComponent<TrendsProps> = ({
	headlineTextColor,
	headline,
	headlineBackgroundColor,
	tiles,
	// messageTile,
	// binaryPoll,
	button,
	banners,
}) => {
	const buttonLabel = button?.label || 'Dej mi víc'

	const elementsGridRow = useMemo(() => {
		const calculate = (type: 'desktop' | 'mobile') => {
			let count = 0

			headline && ++count

			if (type === 'mobile') {
				tiles && (count += tiles.length)
			}

			if (type === 'desktop') {
				tiles && ++count
			}

			// if (button || binaryPoll) {
			// 	++count
			// }

			return count
		}
		return {
			desktop: calculate('desktop'),
			mobile: calculate('mobile'),
		}
	}, [headline, tiles])

	return (
		<>
			{!!(banners || headline) && (
				<div className={style.headline}>
					{banners && (
						<Container size="full">
							<div>
								{banners}
								<Spacer initial={30} />
							</div>
						</Container>
					)}
					{headline && (
						<MarqueeText speed={90} bgColor={headlineBackgroundColor} textColor={headlineTextColor}>
							{headline}
						</MarqueeText>
					)}
				</div>
			)}
			<Container size="full">
				<div
					className={style.wrapper}
					style={
						{
							['--Trends-mobile-grid-rows']: `${elementsGridRow.mobile}`,
							['--Trends-desktop-grid-rows']: `${elementsGridRow.desktop}`,
						} as React.CSSProperties
					}>
					{tiles.map((item, index) => (
						<div key={index} className={style.tile}>
							{item}
						</div>
					))}

					{button && button.url && (
						<div className={style.button}>
							<Button type="link" link={button.url} view="dark" large>
								{buttonLabel}
							</Button>
						</div>
					)}
				</div>
			</Container>
			{/* {binaryPoll && (
				<div className={style.binaryPoll}>
					<BinaryPoll {...binaryPoll} />
				</div>
			)}

			{messageTile && (
				<div className={style.messageTile}>
					<MessagesTile {...messageTile} />
				</div>
			)} */}
		</>
	)
}
