import clsx from 'clsx'
import React from 'react'
import { AuthorBadge } from '../components/AuthorBadge'
import type { AuthorResult } from '../data/fragments/AuthorFragment'
import { ImageSizes } from '../utils/ImageSizesContext'
import style from './AuthorsList.module.sass'
import { Button } from './Button'

export function AuthorsList(props: {
	large?: boolean
	items: AuthorResult[]
	moreVisible?: boolean
}) {
	// const orderedItems = props.items.sort(
	// 	(a, b) => b.paginateArticles.pageInfo.totalCount - a.paginateArticles.pageInfo.totalCount
	// )

	return (
		<ImageSizes
			value={{
				AuthorBadge: `(min-width: 1035px) 60px, 39px`,
			}}>
			<div className={clsx(style.Container, props.large && style.large)}>
				<div className={style.Items}>
					{props.items.map((author) => (
						<div key={author.id} className={style.Item}>
							<AuthorBadge prefix="" {...author} />
						</div>
					))}
				</div>
				{props.moreVisible && (
					<div className={style.button}>
						<Button type="link" link="/autori" view="dark" large>
							Dej mi víc
						</Button>
					</div>
				)}
			</div>
		</ImageSizes>
	)
}
