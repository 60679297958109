import clsx from 'clsx'
import type { LinkResult } from '../data/fragments/LinkFragment'
import type { ProductResult } from '../data/fragments/ProductFragment'
import { Button } from './Button'
import { ContemberImage } from './ContemberImage'
import { MarqueeText } from './MarqueeText'
import style from './WearFomo.module.sass'

export type WearFomoProps = {
	headlineTextColor?: string
	headline?: string
	headlineBackgroundColor?: string
	linkLabel?: string
	link?: LinkResult
	products: ProductResult[]
}

export const WearFomo: React.FunctionComponent<WearFomoProps> = ({
	headlineTextColor,
	headline,
	headlineBackgroundColor,
	linkLabel,
	link,
	products,
}) => {
	return (
		<div className={style.wrapper}>
			{headline && (
				<MarqueeText speed={90} bgColor={headlineBackgroundColor} textColor={headlineTextColor}>
					{headline}
				</MarqueeText>
			)}
			<div className={style.contentIn}>
				<div className={style.tiles}>
					{products.map((product) => (
						<div
							key={product.id}
							className={clsx(style.product, products.length >= 4 && style.moreProducts)}>
							{product.image && (
								<div className={style.image}>
									<ContemberImage image={product.image} layout="fill" objectFit="cover" />
								</div>
							)}
							<h3 className={style.name}>{product.name}</h3>
							{product.price && <h3 className={style.price}>{formatPrice(product.price)}</h3>}
						</div>
					))}
				</div>
				{link && (
					<div className={style.productButton}>
						<Button type="link" view="dark" link={link} large>
							<div>{linkLabel}</div>
						</Button>
					</div>
				)}
			</div>
		</div>
	)
}

function formatPrice(price: number) {
	return <>{(price / 100).toFixed(2).replace('.00', ',-').replace('.', ',')} Kč</>
}
