import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { mailto } from '../utils/mailto'
import { Button } from './Button'
import { Container } from './Container'
import style from './PreFooter.module.sass'
import { MaybeRichText } from './RichText/MaybeRichText'
// import { TagRowGridProps } from './TagRowGrid'

export type PreFooterProps = {
	headline?: string
	// tags?: TagRowGridProps
	callToAction?: CallToActionProps
}

type CallToActionProps = {
	headline?: string
	text?: string
	buttonLabel?: string
}

export const PreFooter: FunctionComponent<PreFooterProps> = (props) => {
	const callToActionLink = mailto({
		to: 'pisu@heyfomo.cz',
		subject: 'Posílám FOMU článek!',
		body: `Baví tě FOMO a chceš být jeho součástí? Pošli nám Tvůj článek, který bys rád/a zveřejnil na našem webu.

		Může se jednat o článek na jakékoliv téma - ať už aktuální nebo nadčasové. Prosíme jen, aby nešlo pouze o holý text. Je třeba, abychom k článku obdrželi i doplňkové materiály - ať už fotky, embedy nebo jiné odkazy.

		Společně s článkem nám vždy pošli tvé celé jméno, instagramový nick a fotku, která se konceptuálně podobá těm, které máme na webu u našich autorů. My si ji už podle potřeb sami upravíme.

		Pokud se nám Tvůj článek bude líbit a hodit do plánu, zveřejníme ho pod tvým jménem. Když nám budeš posílat články pravidelně a my s nimi budeme spokojeni, můžeme se potkat a společně probrat užší spolupráci.

		Tvůj tým FOMO.`,
	})

	return (
		<div className={style.wrapper}>
			<Container size="normal">
				<div className={style.items}>
					{props.callToAction && (
						<div className={clsx(style.item, style.callToAction)}>
							{props.callToAction.headline && (
								<h2 className={style.callToActionHeadline}>
									<MaybeRichText>{props.callToAction.headline}</MaybeRichText>
								</h2>
							)}
							{props.callToAction.text && (
								<p className={style.callToActionText}>{props.callToAction.text}</p>
							)}
							{props.callToAction.buttonLabel && (
								<div className={style.callToActionButton}>
									<Button
										type="link"
										view="light"
										link={callToActionLink}
										large
										dataAttributes={{
											'track-click': JSON.stringify({
												event: 'clickTo_content',
												clickTo: 'submit-work-button',
												link: {
													type: 'submit',
													text: 'Submit your work',
													url: callToActionLink,
												},
											}),
										}}>
										{props.callToAction.buttonLabel}
									</Button>
								</div>
							)}
						</div>
					)}
				</div>
			</Container>
		</div>
	)
}
