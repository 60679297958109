import type { ReactNode } from 'react'
import style from './AstroStories.module.sass'
import { MarqueeText } from './MarqueeText'
import { TileStyleContext, largeOnMobile } from './Tile'
import { TileGrid } from './TileGrid'
import { Container } from './Container'

export type AstroStoriesProps = {
	headlineTextColor?: string
	headline?: string
	headlineBackgroundColor?: string
	tiles: Array<ReactNode>
}

export const AstroStories: React.FunctionComponent<AstroStoriesProps> = ({
	headlineTextColor,
	headline,
	headlineBackgroundColor,
	tiles,
}) => {
	return (
		<div className={style.wrapper}>
			{headline && (
				<MarqueeText speed={90} bgColor={headlineBackgroundColor} textColor={headlineTextColor}>
					{headline}
				</MarqueeText>
			)}
			<Container size="full">
				<div className={style.tiles}>
					<TileStyleContext.Provider value={largeOnMobile}>
						<TileGrid items={tiles} perLine={2} />
					</TileStyleContext.Provider>
				</div>
			</Container>
		</div>
	)
}
